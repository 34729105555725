/** @format */

import { Button, Card, Col, Grid, Row } from "antd";
import axios from "axios";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { Articles } from "../../../interfaces/articles";
import responsiveColumn from "../../../methods/responsiveColumn";
import useWindowSize from "../../../methods/useWindowResize";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import dayjs from "dayjs";
import { convert } from "html-to-text";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Turncate from "../../../methods/turncate";

export default function Section_8() {
	const textContainer = useRef(null);
	const textHeight = (textContainer?.current as any)?.offsetHeight;
	const [imageHeight, setImageHeight] = useState(textHeight);
	const { t } = useTranslation("home_section8");
	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const size = useWindowSize();

	const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);
	useEffect(() => {
		if (!hasInitiallyRendered) setHasInitiallyRendered(true);
		const textHeight = (textContainer?.current as any)?.offsetHeight;
		setImageHeight(textHeight);
	}, [size.width]);

	const [articles, setArticles] = useState<Articles>();

	useEffect(() => {
		fetchArticles();
	}, []);
	const router = useRouter();
	const { locale } = router;

	const fetchArticles = async () => {
		try {
			const r = await axios({
				url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/articles?_embed&per_page=100&${
					locale != "en" ? `lang=${locale}` : ""
				}`,
				method: "get",
			});

			setArticles(r.data);
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<>
			<Row
				justify='center'
				gutter={[40, 40]}
				style={{ paddingTop: 50, paddingBottom: 50, lineHeight: 1.5 }}>
				<Col
					{...responsiveColumn([10, 11, 12, 22, 22, 22])}
					style={{ textAlign: "center" }}>
					<span className='orangeTitle'>
						{hasInitiallyRendered && t("section8_orange")}
					</span>
					<h2
						className='section_title'
						style={{ fontSize: screenSize.md ? 48 : 24 }}>
						{hasInitiallyRendered && t("section8_title")}
					</h2>
				</Col>
				<Col {...responsiveColumn([24, 24, 24, 24, 24, 24])}>
					<Row justify='center' gutter={[50, 50]}>
						{articles?.slice(0, 3).map((article) => {
							return (
								<>
									<Col {...responsiveColumn([7, 7, 7, 10, 10, 23])}>
										<Card
											cover={
												<div
													style={{
														width: "100%",
														height: 200,
														backgroundSize: "cover",
														backgroundPosition: "center center",
														backgroundImage: `url(${
															article?._embedded?.["wp:featuredmedia"]?.[0]
																?.source_url ?? "/blank_placeholder.png"
														})`,
													}}></div>
											}
											hoverable>
											<div style={{ margin: -5 }}>
												<div
													style={{
														color: "#254486",
														fontSize: 14,
														height: 50,
														marginBottom: 20,
													}}>
													{article._embedded.author[0]?.name} |{" "}
													{dayjs(article.date).format("DD MMMM YYYY")} |{" "}
													{article._embedded?.["wp:term"]?.[0]?.[0]?.name}{" "}
												</div>
												<Card.Meta
													title={
														<span style={{ fontWeight: 600 }}>
															{convert(article.title.rendered)}
														</span>
													}
													description={
														<p style={{ height: 100 }}>
															{Turncate(convert(article.content.rendered), 200)}
														</p>
													}
												/>
												<Button
													type='link'
													href={`/articles/${article?.slug}`}
													key={article.id}
													style={{
														float: "left",
														border: "none",
														background: "none",
														padding: 0,
														marginTop: 10,
													}}>
													<span
														style={{
															textDecoration: "underline",
															float: "left",
															color: "#254486",
														}}>
														{hasInitiallyRendered && t("section8_read_more")}
													</span>
												</Button>
											</div>
										</Card>
									</Col>
								</>
							);
						})}
					</Row>
				</Col>
				<Col span={24} style={{ textAlign: "center" }}>
					<Link href='/resources/articles'>
						<Button size='large' type='primary' style={{ fontSize: 15 }}>
							{hasInitiallyRendered && t("section8_button")}
						</Button>
					</Link>
				</Col>
			</Row>
		</>
	);
}
