/** @format */

import {
  CopyrightOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  TwitterOutlined,
  YoutubeFilled,
} from "@ant-design/icons";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { LocationFilled } from "@carbon/icons-react";

import { Col, Divider, Grid, Row, Space, Typography } from "antd";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import responsiveColumn from "../../methods/responsiveColumn";
import { locations } from "../locations";
import { useEffect, useState } from "react";
import axios from "axios";
import { useRouter } from "next/router";

export default function HomeFooter() {
  const { t } = useTranslation("footer");
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  // const [locationData, setLocationData] = useState<any>();

  // const fetchData = async () => {
  //   try {
  //     const r = await axios({
  //       url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/location/?_embed=&per_page=100`,
  //       method: "get",
  //     });
  //     setLocationData(r?.data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  // const { locale } = useRouter();

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <Row
        style={{ background: "#254486", color: "#fff", paddingBottom: 50 }}
        justify='center'>
        <Col
          {...responsiveColumn([20, 20, 21, 22, 22, 22])}
          className='wide-content'>
          <Row
            gutter={[50, 40]}
            style={{
              color: "#fff",
              paddingTop: 50,
              lineHeight: 1.5,
              fontSize: 16,
              justifyContent: "center",
            }}
            justify='space-between'>
            <Col
              className='wide-content'
              {...responsiveColumn([9, 10, 11, 12, 23, 23])}
              style={{ fontSize: 16 }}>
              <Link href='/' style={{ display: "inline-block" }}>
                <Image
                  src='/TWGWhiteLogo.png'
                  alt='TWG-White-Logo'
                  className='stext5'
                  width={358}
                  height={57}
                  style={{
                    maxWidth: "100%",
                  }}
                />
              </Link>
              <div style={{ marginTop: 30, marginBottom: 30 }}>
                {t("company_description")}
              </div>
              <Space direction='vertical' size={"middle"}>
                <Space size='large'>
                  <Image
                    src={"/icons/phone.svg"}
                    alt='icon'
                    width={20}
                    height={20}
                  />
                  {/* <PhoneOutlined
                    style={{ fontSize: screenSize.md ? 16 : 22 }}
                  /> */}
                  <a
                    href='tel:8662829333'
                    style={{ color: "white", fontSize: 16, fontWeight: "400" }}>
                    {" "}
                    (866) 2TaxDefense (282-9333)
                  </a>
                </Space>
                <Space size='large'>
                  <Image
                    src={"/icons/email.svg"}
                    // //preview={false}
                    style={{ height: 15 }}
                    alt='icon'
                    width={20}
                    height={15}
                  />
                  {/* <MailOutlined style={{ fontSize: screenSize.md ? 16 : 22 }} /> */}
                  <a
                    href='mailto:contact@taxworkoutgroup.com'
                    style={{ color: "white", fontSize: 16, fontWeight: "400" }}>
                    {" "}
                    contact@taxworkoutgroup.com
                  </a>
                </Space>
                <Space size='large'>
                  <Image
                    src={"/icons/printer.svg"}
                    // //preview={false}
                    style={{ height: 20 }}
                    alt='icon'
                    width={20}
                    height={20}
                  />
                  {/* <PrinterOutlined
                    style={{ fontSize: screenSize.md ? 16 : 22 }}
                  /> */}
                  <a
                    href='fax:+18665112384'
                    style={{ color: "white", fontSize: 16, fontWeight: "400" }}>
                    {" "}
                    (866) 511-2384
                  </a>
                </Space>
              </Space>
            </Col>
            <Col
              style={{
                width: "100%",
                display: screenSize.md ? "none" : "initial",
              }}>
              <hr style={{ border: "1px solid #6893c34d" }} />
            </Col>
            <Col
              {...responsiveColumn([12, 13, 13, 23, 23, 23])}
              style={{ textAlign: screenSize.lg ? "left" : "center" }}>
              <Row>
                <Col
                  {...responsiveColumn([8, 8, 8, 23, 23, 23])}
                  style={{ marginBottom: screenSize.lg ? 0 : 50 }}>
                  <Space direction='vertical' size='large'>
                    <em
                      style={{
                        fontSize: 20,
                        fontWeight: screenSize.md ? 500 : 600,
                        fontStyle: "inherit",
                      }}>
                      {t("practice")}
                    </em>
                    <Link
                      href='/taxbankruptcy'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("bankruptcy")}
                    </Link>
                    <Link
                      href='/taxcontroversy'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("controversy")}
                    </Link>
                    <Link
                      href='/tax-compliance'
                      style={{
                        color: "rgba(255,255,255,0.85)",
                        fontSize: 16,
                        cursor: "",
                      }}>
                      {t("compliance")}
                    </Link>
                    <Link
                      href='/criminal-tax-defense'
                      style={{
                        color: "rgba(255,255,255, 0.85)",
                        fontSize: 16,
                      }}>
                      {t("criminal")}
                    </Link>
                  </Space>
                </Col>
                <Col
                  {...responsiveColumn([8, 8, 8, 23, 23, 23])}
                  style={{ marginBottom: screenSize.lg ? 0 : 50 }}>
                  <Space direction='vertical' size='large'>
                    <em
                      style={{
                        fontSize: 20,
                        fontWeight: screenSize.md ? 500 : 600,
                        fontStyle: "inherit",
                      }}>
                      Resources
                    </em>
                    <Link
                      href='/resources/articles'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("articles")}
                    </Link>
                    <Link
                      href='/resources/blog'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("blogs")}
                    </Link>
                    {/* <Link
                      href="/resources/assistance_resources"
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}
                    >
                      {t("assistance_resources")}
                    </Link> */}
                    <Link
                      href='/resources/tax-resource-links'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("links")}
                    </Link>
                    <Link
                      href='/resources/video-library'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("video")}
                    </Link>
                    <Link
                      href='/resources/press-releases'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("press")}
                    </Link>
                  </Space>
                </Col>
                <Col
                  {...responsiveColumn([8, 8, 8, 23, 23, 23])}
                  style={{ marginBottom: screenSize.lg ? 0 : 10 }}>
                  <Space direction='vertical' size='large'>
                    <em
                      style={{
                        fontSize: 20,
                        fontWeight: screenSize.md ? 500 : 600,
                        fontStyle: "inherit",
                      }}>
                      Company
                    </em>
                    <Link
                      href='/new-clients-intake-overview'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("new_clients")}
                    </Link>
                    <Link
                      href='/about-us/our-team'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("team")}
                    </Link>
                    <Link
                      href='/about-us/firm-overview'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("overview")}
                    </Link>
                    <Link
                      href='/faqs'
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.85)" }}>
                      {t("faqs")}
                    </Link>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              paddingTop: "30px",
              textAlign: "center",
              rowGap: 50,
            }}>
            <Divider
              style={{
                background: "rgba(255, 255, 255, 0.12)",
                display: screenSize.lg ? "inherit" : "none",
              }}
            />
          </Row>
          <Divider
            style={{
              background: "rgba(255, 255, 255, 0.12)",
              marginTop: screenSize.lg ? 24 : -10,
            }}
          />
          <Row>
            <Col
              {...responsiveColumn([12, 12, 12, 23, 23, 23])}
              style={{ textAlign: screenSize.lg ? "left" : "center" }}>
              <Space size={"large"} style={{ fontSize: 24 }}>
                <Link
                  href='https://www.instagram.com/taxworkoutgroup/'
                  style={{ color: "#fff" }}
                  target='_blank'>
                  <InstagramOutlined
                    style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                  />
                </Link>
                <Link
                  href='https://www.facebook.com/Taxworkoutgroup/'
                  style={{ color: "#fff" }}
                  target='_blank'>
                  <FacebookFilled
                    style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                  />
                </Link>
                <Link
                  href='https://twitter.com/Taxworkoutgroup'
                  style={{ color: "#fff" }}
                  target='_blank'>
                  <TwitterOutlined
                    style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                  />
                </Link>
                <Link
                  href='https://www.youtube.com/channel/UCjG4Y98ZN1vyxUWQFXAwYyA'
                  style={{ color: "#fff" }}
                  target='_blank'>
                  <YoutubeFilled
                    style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                  />
                </Link>
                <Link
                  href='https://www.linkedin.com/company/taxworkoutgroup/'
                  style={{ color: "#fff" }}
                  target='_blank'>
                  <LinkedinFilled
                    style={{ margin: screenSize.md ? "0px" : "0px 5px" }}
                  />
                </Link>
              </Space>
            </Col>
            <Col
              {...responsiveColumn([12, 12, 12, 23, 23, 20])}
              style={{
                textAlign: screenSize.lg ? "right" : "center",
                margin: screenSize.lg ? "0px" : "20px auto",
              }}>
              <Link href='/' className='copyRightText'>
                <CopyrightOutlined style={{ color: "#fff" }} />{" "}
                {new Date().getFullYear()} Tax Workout Group
              </Link>
              <Divider style={{ backgroundColor: "#fff" }} type='vertical' />
              <Link
                style={{ color: "#fff", fontWeight: "400" }}
                href={`/disclaimer`}>
                {t("disclaimer")}
              </Link>
              <Divider style={{ backgroundColor: "#fff" }} type='vertical' />
              <Link
                style={{ color: "#fff", fontWeight: "400" }}
                href={`/privacy-policy`}>
                {t("privacy")}
              </Link>
              <Divider style={{ backgroundColor: "#fff" }} type='vertical' />
              <Link
                style={{ color: "#fff", fontWeight: "400" }}
                href={`/accesibility-statement`}>
                {t("accessibility")}
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
