import { Col, Row } from "antd";
import responsiveColumn from "../../methods/responsiveColumn";
import AwardsBanner from "./AwardsBanner";
import { useTranslation } from "next-i18next";

export default function Awards() {
  const { t } = useTranslation("professional_association");
  return (
    <Row>
      <Col span={24}>
        <Row
          style={{
            // background: "#254486",
            padding: "40px 30px 0",
            paddingBottom: 40,
            color: "#000",
          }}
          justify="center"
        >
          <Col
            {...responsiveColumn([12, 12, 12, 23, 23, 23])}
            style={{ textAlign: "center" }}
          >
            <h2 className="section_title">{t("pro_asso_head")}</h2>
          </Col>
          <Row justify="center" gutter={[0, 20]} style={{ padding: "40px 0" }}>
            <Col {...responsiveColumn([24, 24, 24, 24, 24, 24])}>
              <AwardsBanner />
            </Col>
          </Row>
        </Row>
      </Col>
    </Row>
  );
}
