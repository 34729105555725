/** @format */

import Image from "next/image";
import { CSSProperties } from "react";
import { Autoplay } from "swiper";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

const photos = [
	// {
	// 	title: "American Society of Tax Problem Solvers",
	// 	image: "/awards/award1.webp"
	// },
	{
		title: "Certified Public Accountant",
		image: "/awards/award_c.webp",
		loading: "lazy",
	},
	{ title: "AV Preeminent", image: "/awards/award_d.webp", loading: "lazy" },
	// {
	// 	title: "Accredited Business A+ Rating BBB",
	// 	image: "/awards/award_e.webp",
	// 	loading: "lazy",
	// },
	{
		title: "JUSTIA",
		image: "/awards/JUSTIA.png",
		loading: "lazy",
	},
	{
		title: "AVVO Rating 10 Top ",
		image: "/awards/award_f.webp",
		loading: "lazy",
	},
	{
		title: "US District Court",
		image: "/awards/award_g.webp",
		loading: "lazy",
	},
	{
		title: "Bar Register Preeminent Lawyers",
		image: "/awards/award_h.webp",
		loading: "lazy",
	},
	{ title: "US Tax Court", image: "/awards/award_i.webp", loading: "lazy" },
	{
		title: "Florida Department of Revenue",
		image: "/awards/award_j.webp",
		loading: "lazy",
	},
	{
		title: "Florida Institute of Certified Public Accountants",
		image: "/awards/award_k.webp",
		loading: "lazy",
	},
	{
		title: "The Florida Bar 1950",
		image: "/awards/award_l.webp",
		loading: "lazy",
	},
	{
		title: "National Association of Tax Professionals",
		image: "/awards/NATP-logo.webp",
		loading: "lazy",
	},
	{
		title: "Tax Section of the Florida Bar",
		image: "/awards/tax-section-florida-bar.webp",
		loading: "lazy",
	},
];

export default function AwardsBanner({
	sx,
}: {
	sx?: CSSProperties | undefined;
}) {
	const style = sx || { marginTop: 60 };

	return (
		<Swiper
			spaceBetween={90}
			slidesPerView={"auto"}
			centeredSlides={true}
			loop={true}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			observer={true}
			modules={[Autoplay]}
			style={style}>
			{photos.map((photo, index) => (
				<SwiperSlide key={photo.title} style={{ width: "auto" }}>
					<Image
						src={photo.image}
						alt={photo.title}
						height={90}
						width={100}
						className='stext5'
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
