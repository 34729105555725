/* eslint-disable @next/next/next-script-for-ga */
import type {FC} from 'react';
import { useCommonContext } from '../../context/CommonContext';
import Head from 'next/head';

interface HeadScriptTagProps{
  isThankYouPage?: boolean;
}

export const HeadScriptTag: FC<HeadScriptTagProps> = ({ isThankYouPage= false})=>{
	const {email, phone_number} = useCommonContext();

  return(
    <Head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11268611599" />
      {/* Initialize the script after it has been loaded */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-11268611599', { 'allow_enhanced_conversions': true });
            gtag('config', 'G-MKTTB66XPM');
          `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            gtag('set', 'user_data', {
              "email": "${email}",
              "phone_number": "${phone_number}",
            });
          `,
        }}
      />
      {isThankYouPage && (
        <script
          id="google-ads-thank-you-page-script"
          dangerouslySetInnerHTML={{
            __html: `
            gtag('event', 'conversion', {'send_to': 'AW-11268611599/9AAbCLyl-L4YEI-8pf0p'});
            `,
          }}
        />
      )}
    </Head>
  );
}

