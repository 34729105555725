/** @format */

import { Button, Col, Grid, Row } from "antd";
import Link from "next/link";
import { useRef } from "react";
import responsiveColumn from "../../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";
import Image from "next/image";

export default function Section_2() {
	const textContainer = useRef(null);
	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const { t } = useTranslation("home_section2");

	return (
		<Row
			justify={"center"}
			gutter={[30, 30]}
			style={{
				background: "#254486",
				paddingTop: 60,
				paddingBottom: 50,
				lineHeight: 1.5,
			}}>
			<Col span={24}>
				<Row justify={"center"}>
					<Col
						style={{ textAlign: screenSize.md ? "center" : "left" }}
						{...responsiveColumn([21, 24, 24, 22, 22, 22])}>
						<span className='orangeTitle'>{t("section2_orange")}</span>
						<h2 style={{ color: "#fff" }} className='section_title'>
							{t("section2_title")}
						</h2>
					</Col>
					<Row
						gutter={[30, 60]}
						justify='center'
						align='top'
						style={{ paddingTop: 30 }}>
						<Col
						// {...responsiveColumn([6, 8, 8, 9, 10, 11, 12])}
						// style={{
						//   textAlign: "center",
						//   background: 'url("/section_2_hero.png")',
						//   width: "100%",
						//   height: imageHeight,
						//   backgroundSize: "cover",
						//   backgroundPosition: "center center",
						//   backgroundRepeat:"no-repeat",
						// }}
						>
							<Image
								src='/section_2_hero_a.webp'
								// //preview={false}? 512 : 540) : 527}
								width={screenSize.sm ? (screenSize.lg ? 457 : 400) : 337}
								alt='Virtual Tax Firm'
								height={!screenSize.lg ? 590 : 634}
								className='stext5'
							/>
						</Col>
						<Col
							ref={textContainer}
							{...responsiveColumn(
								[14, 14, 10, 22, 22, 22],
								[1, 1, 1, 2, 2, 2]
							)}
							style={{
								color: "#fff",
								lineHeight: "32px",
								fontSize: 20,
								marginLeft: screenSize.md ? 25 : 0,
								maxWidth: 640,
							}}>
							<div style={{ wordBreak: "break-word", maxWidth: "36rem" }}>
								{t("section2_p1")}
							</div>
							<div style={{ marginTop: 32, fontSize: 16 }}>
								<b>{t("section2_p2")}</b>
								{t("section2_p2_0")}
							</div>
							<div style={{ marginTop: 32, fontSize: 16 }}>
								{t("section2_p3")}
							</div>
							<div
								style={{
									marginTop: screenSize.md ? 50 : 35,
									textAlign: screenSize.md ? "left" : "center",
								}}>
								<Link href='/about-us/our-unique-practice-model'>
									<Button
										type='default'
										size='large'
										style={{
											background: "#DE9E36",
											border: "#DE9E36",
											color: "#fff",
											fontWeight: 15,
											paddingLeft: 20,
											paddingRight: 20,
										}}>
										{t("section2_button")}
									</Button>
								</Link>
							</div>
						</Col>
					</Row>
				</Row>
			</Col>
		</Row>
	);
}
