/** @format */

import { Button, Col, Grid, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import useWindowSize from "../../../methods/useWindowResize";
import Link from "next/link";
import responsiveColumn from "../../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";

export default function Section_4() {
  const textContainer = useRef(null);
  const textHeight = (textContainer?.current as any)?.offsetHeight;
  const [imageHeight, setImageHeight] = useState(textHeight);
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const size = useWindowSize();
  const { t } = useTranslation("home_section4");

  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);
  useEffect(() => {
    if (!hasInitiallyRendered) setHasInitiallyRendered(true);
    const textHeight = (textContainer?.current as any)?.offsetHeight;
    setImageHeight(textHeight);
  }, [size.width]);

  return (
    <>
      <Row
        style={{ background: "#254486", padding: "50px 0", color: "#fff" }}
        justify='center'
        gutter={[30, 30]}>
        <Col
          style={{ textAlign: "center", lineHeight: 1.5 }}
          {...responsiveColumn([14, 16, 15, 16, 23, 23])}>
          <h2
            className='section_title'
            style={{ fontSize: screenSize.md ? 48 : 20, fontWeight: "600" }}>
            {hasInitiallyRendered && t("section4_title")}{" "}
          </h2>
          <div
            style={{
              fontSize: 16,
              marginTop: 40,
              marginBottom: 30,
              padding: "0 26px",
              fontWeight: "bold",
            }}>
            {hasInitiallyRendered && t("section4_p")}
            <b style={{ fontWeight: "400" }}>
              {hasInitiallyRendered && t("section4_p0_b")}
            </b>
          </div>
          <Link href='/about-us/our-unique-practice-model'>
            <Button
              type='default'
              size='large'
              style={{
                marginTop: 5,
                marginBottom: 0,
                background: "#DE9E36",
                border: "#DE9E36",
                color: "#fff",
                fontSize: "15px",
                paddingLeft: screenSize.md ? 100 : 60,
                paddingRight: screenSize.md ? 100 : 60,
                textAlign: "center",
              }}>
              {hasInitiallyRendered && t("section4_button")}
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  );
}
