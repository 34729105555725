/** @format */

import { Carousel, Col, Grid, Row } from "antd";
import axios from "axios";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import type { Articles } from "../../../interfaces/articles";
import useWindowSize from "../../../methods/useWindowResize";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { convert } from "html-to-text";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import responsiveColumn from "../../../methods/responsiveColumn";
import BirdeyeWidgetHome from "../BirdeyeWidgetHome";

export default function Section_7() {
  const textContainer = useRef(null);
  const textHeight = (textContainer?.current as any)?.offsetHeight;
  const [_imageHeight, setImageHeight] = useState(textHeight);
  const { t } = useTranslation("home_section7");
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const size = useWindowSize();

  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);
  useEffect(() => {
    if (!hasInitiallyRendered) setHasInitiallyRendered(true);
    const textHeight = (textContainer?.current as any)?.offsetHeight;
    setImageHeight(textHeight);
  }, [size.width]);

  const [reviews, setReviews] = useState<Articles>();

  const router = useRouter();
  const { locale } = router;
  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/reviews?_embed&per_page=100&${
          locale != "en" ? `lang=${locale}` : ""
        }`,
        method: "get",
      });

      setReviews(r.data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Row
        style={{
          background: "#254486",
          color: "#fff",
          lineHeight: 1.5,
          paddingTop: 50,
          paddingBottom: 50,
        }}
        justify="center"
      >
        <Col span={24} style={{ textAlign: "center" }}>
          <span className="orangeTitle">
            {hasInitiallyRendered && t("section7_orange")}
          </span>
          <h2
            className="section_title"
            style={{ fontSize: screenSize.md ? 48 : 24 }}
          >
            {hasInitiallyRendered && t("section7_title")}
          </h2>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Carousel>
            {reviews?.map((review) => {
              return (
                <div
                  key={review.id}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingBottom: 60,
                  }}
                >
                  <Row justify={"center"}>
                    <Col
                      style={{
                        color: "#fff",
                        textAlign: "center",
                      }}
                      {...responsiveColumn([16, 17, 13, 18, 18, 18])}
                    >
                      <div style={{ marginBottom: -10, textAlign: "center" }}>
                        <Image
                          src="/starts-review.png"
                          height={80}
                          width={190}
                          alt="review"
                          style={{
                            display: "inline-block",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: screenSize.md ? "justify" : "center",
                          fontSize: 16,
                          width: "calc(100% - 50px)",
                          margin: "0 auto",
                        }}
                      >
                        {convert(review.content.rendered)}
                      </div>
                      <div
                        style={{
                          marginTop: 20,
                          fontStyle: "italic",
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        - {review.title.rendered}
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Carousel>
        </Col>
        <Col span={23} style={{ textAlign: "center", marginTop: 25 }}>
          <BirdeyeWidgetHome />
        </Col>
      </Row>
    </>
  );
}
