import { useEffect } from "react";

const BirdeyeWidgetHome = () => {
  useEffect(() => {
    const scriptId = "birdeye-script";
    const widgetId = "bf-revz-widget-987654321301015183";

    const initializeScript = () => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.type = "text/javascript";
        script.src =
          "https://birdeye.com/embed/v7/171598355822954/10/987654321301015183";
        script.async = true;
        document.body.appendChild(script);
      }
    };

    const resetWidgetContainer = () => {
      const widgetContainer = document.getElementById(widgetId);
      if (widgetContainer) {
        widgetContainer.innerHTML = "";
      }
    };

    resetWidgetContainer();
    initializeScript();

    return () => {
      resetWidgetContainer();
    };
  }, []);

  return <div id="bf-revz-widget-987654321301015183"></div>;
};

export default BirdeyeWidgetHome;
