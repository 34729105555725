/** @format */

import { Button, Col, Collapse, Grid, Row, Spin } from "antd";
import Link from "next/link";
import axios from "axios";
import { useEffect, useState, useRef, useMemo } from "react";
import responsiveColumn from "../../../methods/responsiveColumn";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import type { Articles } from "../../../interfaces/articles";
import { useTranslation } from "next-i18next";
import useWindowSize from "../../../methods/useWindowResize";
import { useRouter } from "next/router";
import { homeTagId } from "../../pages";

export default function Section_5() {
  const textContainer = useRef(null);
  const textHeight = (textContainer?.current as any)?.offsetHeight;
  const [imageHeight, setImageHeight] = useState(textHeight);
  const { t } = useTranslation("home_section5");
  const { useBreakpoint } = Grid;
  const size = useWindowSize();
  const screenSize = useBreakpoint();
  const router = useRouter();
  const { locale } = router;

  const setQueryParam = useMemo(() => {
    if (locale !== "en") {
      return `?lang=${locale}&`;
    } else {
      return "?";
    }
  }, [locale]);

  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);

  useEffect(() => {
    if (!hasInitiallyRendered) setHasInitiallyRendered(true);
    const textHeight = (textContainer?.current as any)?.offsetHeight;
    setImageHeight(textHeight);
  }, [size.width]);

  const [faqs, setFaqs] = useState<Articles>();
  const fetchFAQS = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/faqs${setQueryParam}_embed&per_page=100&tags=${homeTagId}`,
        method: "get",
      });
      setFaqs(r.data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchFAQS();
  }, [locale]);

  return (
    <Row style={{ padding: screenSize.md ? 50 : "50px 0" }} gutter={[20, 20]}>
      <Col span={24}>
        <Row justify={"center"}>
          <Col
            {...responsiveColumn([12, 12, 12, 22, 22, 22])}
            style={{ textAlign: "center", lineHeight: 1.5 }}
          >
            <span className="orangeTitle">
              {hasInitiallyRendered && t("section5_orange")}
            </span>
            <h2
              className="section_title"
              style={{
                fontSize: screenSize.md ? 48 : 24,
                marginBottom: screenSize.md ? 0 : 0,
              }}
            >
              {hasInitiallyRendered && t("section5_title")}
            </h2>
          </Col>
        </Row>
        <Row
          justify={"center"}
          style={{ padding: screenSize.md ? 30 : "0px 0 30px" }}
        >
          <Col {...responsiveColumn([14, 17, 17, 22, 22, 22])}>
            <Spin spinning={!faqs ? true : false}>
              <Collapse
                expandIconPosition="right"
                ghost
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusCircleOutlined
                      style={{
                        fontSize: 20,
                      }}
                    />
                  ) : (
                    <PlusCircleOutlined
                      style={{
                        fontSize: 20,
                      }}
                    />
                  )
                }
              >
                {faqs
                  ?.slice()
                  .reverse()
                  .map((e) => {
                    return (
                      <Collapse.Panel
                        key={e.id}
                        header={
                          <span
                            style={{
                              fontSize: 16,
                              fontWeight: screenSize.md ? 500 : 400,
                            }}
                          >
                            {e.title.rendered}
                          </span>
                        }
                        style={{ paddingTop: 20 }}
                      >
                        <p
                          className="wp"
                          dangerouslySetInnerHTML={{
                            __html: e.content.rendered ?? "",
                          }}
                          style={{
                            overflow: "hidden",
                            width: "100%",
                            fontSize: 14,
                            lineHeight: 2,
                          }}
                        ></p>
                      </Collapse.Panel>
                    );
                  })}
              </Collapse>
            </Spin>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <Link href="/faqs">
              <Button
                size="large"
                type="primary"
                style={{ fontSize: 15, paddingLeft: 20, paddingRight: 20 }}
              >
                {hasInitiallyRendered && t("section5_button")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
