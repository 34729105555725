export default function responsiveColumn(sizes: number[], orders?:number[]) {
  return {
    xxl: {
      span: sizes[0],
      order:orders?.[0]
    },
    xl: {
      span: sizes[1],
      order:orders?.[1]
    },
    lg: {
      span: sizes[2],
      order:orders?.[2]
    },
    md: {
      span: sizes[3],
      order:orders?.[3]
    },
    sm: {
      span: sizes[4],
      order:orders?.[4]
    },
    xs: {
      span: sizes[5],
      order:orders?.[5]
    },
  };
}
