/** @format */

import { Carousel, Col, Grid, Row } from "antd";
import axios from "axios";
import Link from "next/link";
import { useEffect, useState, useRef } from "react";
import useWindowSize from "../../../methods/useWindowResize";
import type { Articles } from "../../../interfaces/articles";
import responsiveColumn from "../../../methods/responsiveColumn";
import { useTranslation } from "next-i18next";
import { homeTagId } from "../../pages";

export default function Section_6() {
  const textContainer = useRef(null);
  const textHeight = (textContainer?.current as any)?.offsetHeight;
  const [imageHeight, setImageHeight] = useState(textHeight);
  const { t } = useTranslation("home_section6");
  const { useBreakpoint } = Grid;
  const size = useWindowSize();
  const screenSize = useBreakpoint();

  const [hasInitiallyRendered, setHasInitiallyRendered] = useState(false);
  useEffect(() => {
    if (!hasInitiallyRendered) setHasInitiallyRendered(true);
    const textHeight = (textContainer?.current as any)?.offsetHeight;
    setImageHeight(textHeight);
  }, [size.width]);

  const [attorneys, setAttorneys] = useState<any>();
  // const awards = ["/awards1.png", "/awards1.png", "/awards1.png"];
  const fetchAttorneys = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/attorneys?_embed&tags=${homeTagId}`,
        method: "get",
      });

      setAttorneys((r.data as Articles).filter((a) => a.tags.includes(24)));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAttorneys();
  }, []);

  return (
    <>
      <Row
        style={{
          background: "#254486",
          color: "#fff",
          margin: 0,
          paddingTop: 50,
        }}
        justify="center"
        gutter={[30, 30]}
      >
        <Col
          {...responsiveColumn([12, 12, 12, 23, 23, 23])}
          style={{ textAlign: "center" }}
        >
          <span className="orangeTitle">
            {hasInitiallyRendered && t("section6_orange")}
          </span>
          <h2
            className="section_title"
            style={{ fontSize: screenSize.md ? 48 : 24 }}
          >
            {hasInitiallyRendered && t("section6_title")}
          </h2>
        </Col>
        <Col span={23} style={{ textAlign: "center", color: "#fff" }}>
          <Carousel
            dots={true}
            arrows
            style={{ marginLeft: 10, marginRight: 10 }}
          >
            {attorneys?.map((attorney: any) => {
              return (
                <div key={attorney.id}>
                  <div
                    style={{
                      width: "100%",
                      color: "#fff",
                      padding: "0 0 0 0 ",
                    }}
                  >
                    <Row
                      justify={"center"}
                      gutter={[40, 40]}
                      style={{ height: "100%" }}
                    >
                      <Col
                        {...responsiveColumn(
                          [9, 11, 12, 22, 22, 22],
                          [1, 1, 1, 2, 2, 2]
                        )}
                        style={{
                          textAlign: screenSize.md ? "left" : "center",
                          paddingBottom: 50,
                        }}
                      >
                        <div style={{ fontSize: 34 }}>
                          {attorney?.title?.rendered}
                        </div>
                        <div
                          style={{
                            marginTop: 36,
                            textAlign: screenSize.md ? "left" : "center",
                            fontSize: 16,
                            lineHeight: "24px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: attorney?.acf?.home_slider_text ?? "",
                          }}
                        ></div>
                        <div style={{ marginTop: 36 }}>
                          <Link
                            href="/about-us/our-team"
                            style={{
                              color: "#fff",
                              textDecoration: "underline",
                              fontSize: 16,
                            }}
                          >
                            {t("section6_full_team")}
                          </Link>
                        </div>
                      </Col>
                      <Col
                        {...responsiveColumn(
                          [7, 7, 7, 16, 16, 16],
                          [2, 2, 2, 1, 1, 1]
                        )}
                        style={{
                          textAlign: "center",
                          position: "relative",
                          height: "100%",
                          paddingBottom: 0,
                        }}
                      >
                        <div
                          style={{
                            width: screenSize.md ? 274 : 189,
                            height: screenSize.md ? 450 : 245,
                            margin: "30px auto 20px",
                            marginTop: 30,
                            backgroundImage: `url(${
                              attorney?._embedded?.["wp:featuredmedia"]?.[0]
                                ?.source_url ?? "/blank_placeholder.png"
                            })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </>
  );
}
