/** @format */

import { Button, Col, Grid, Row } from "antd";
import Link from "next/link";
import responsiveColumn from "../../../methods/responsiveColumn";
import HomeForm from "./HomeForm";
import { useTranslation } from "next-i18next";

export default function Section_1() {
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("home_section1");

  return (
    <>
      <Row
        justify={"center"}
        style={{ paddingTop: 80, paddingBottom: 45, lineHeight: 1.5 }}
      >
        <Col {...responsiveColumn([21, 21, 21, 23, 23, 23])}>
          <Row gutter={[46, 46]} justify="center">
            <Col
              {...responsiveColumn(
                [12, 12, 12, 23, 23, 23],
                [1, 1, 1, 2, 2, 2]
              )}
              style={{ lineHeight: 1.5, maxWidth: 686 }}
            >
              <div style={{ marginLeft: "auto", marginRight: "0px" }}>
                <span className="orangeTitle">{t("section1_orange")}</span>
                <br />
                <h2
                  className="section_title"
                  style={{ marginBottom: 0, marginTop: 10 }}
                >
                  {t("section1_title")}
                </h2>
                <div style={{ marginTop: 18 }}>
                  <p style={{ marginBottom: "5px" }}>
                    <b style={{ fontSize: 20, lineHeight: 1.3 }}>
                      {t("section1_p1")}
                    </b>
                  </p>
                  <p style={{ marginTop: 24, fontSize: 16 }}>
                    {t("section1_p2")}
                  </p>
                  <p style={{ marginTop: 18, fontSize: 16 }}>
                    {t("section1_p3")}
                  </p>
                  {/* <p style={{ marginTop: 18, fontSize: 16 }}>
                    {t("section1_p4")}
                  </p> */}
                </div>
              </div>
              <div
                style={{
                  marginTop: 30,
                  textAlign: screenSize.md ? "left" : "center",
                }}
              >
                <Link href="/about-us/our-unique-practice-model">
                  <Button
                    type="primary"
                    size="large"
                    style={{
                      fontWeight: 15,
                      paddingLeft: 25,
                      paddingRight: 25,
                    }}
                  >
                    {t("section1_button")}
                  </Button>
                </Link>
              </div>
            </Col>
            <Col
              {...responsiveColumn(
                [12, 12, 12, 23, 23, 23],
                [2, 2, 2, 1, 1, 1]
              )}
              style={{ maxWidth: 600 }}
            >
              <HomeForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
