/* eslint-disable @typescript-eslint/no-explicit-any */
/** @format */

import { Col, Grid, Row } from "antd";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import style from "./home.module.sass";
import type { VideoComponentProps } from "../../pages";
import { useTheme } from "@mui/material";


export default function VideoComponent({videoURL}: VideoComponentProps) {
	const [muted, setMuted] = useState(true);
	const { useBreakpoint } = Grid;
	const screenSize = useBreakpoint();
	const { t } = useTranslation("home");
  const theme = useTheme();

	const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.load(); // Load the video
      video.play(); // Play the video
    }
  }, []);

	const toggleFullScreen = () => {
		// eslint-disable-next-line no-var
		var el = document.getElementById("back_video");
		if (!el) return;
		if (el.requestFullscreen) {
			el.requestFullscreen();
		} else if ((el as any).msRequestFullscreen) {
			(el as any).msRequestFullscreen();
		} else if ((el as any).mozRequestFullScreen) {
			(el as any).mozRequestFullScreen();
		} else if ((el as any).webkitRequestFullscreen) {
			(el as any).webkitRequestFullscreen();
		}
	};

	return (
		<>
			<Row>
				<Col span={24} className={style.Video_Section}>
					<div style={{ height: "100%" }}>
						<div
							style={{
								position: "absolute",
								zIndex: 99,
								left: screenSize.lg ? "10%" : 0,
								right: screenSize.lg ? "auto" : 0,
								marginRight: screenSize.lg ? 0 : "auto",
								marginLeft: screenSize.lg ? 0 : "auto",
								bottom: 97,
								width: screenSize.md ? "80%" : "90%",
								lineHeight: 1.5,
								maxWidth: 641,
								textAlign: screenSize.lg ? "left" : "center",
							}}>
							<h3
                style={{
                  color: "#fff",
                  fontSize: screenSize.md
                    ? theme.typography.pxToRem(20)
                    : theme.typography.pxToRem(16),
                }}
              >
                {t("landing_upSubtitle")}
              </h3>
              <h1
                className={screenSize.md ? "garamond" : "raleway"}
                style={{
                  color: "#fff",
                  fontSize: screenSize.md
                    ? theme.typography.pxToRem(45)
                    : theme.typography.pxToRem(34),
                  marginTop: theme.spacing(3),
                  fontWeight: 400,
                }}
              >
                {t("landing_title")}
              </h1>
              <h3
                style={{
                  color: "#fff",
                  fontSize: screenSize.md
                    ? theme.typography.pxToRem(20)
                    : theme.typography.pxToRem(16),
                }}
              >
                {t("landing_downSubtitle")}
              </h3>
						</div>
						<div
							style={{
								position: "absolute",
								right: "12%",
								bottom: 25,
								textAlign: "left",
								maxWidth: 550,
								zIndex: 99,
							}}>
							<div
								style={{ color: "#fff", display: "inline-block" }}
								onClick={() => setMuted(!muted)}>
								<Image
									src='/mute.png'
									width={48}
									height={50}
									alt='mute icon'
									priority
									style={{
										cursor: "pointer",
										width: 48,
										filter: `saturate(${muted ? 0 : 60})`,
										transition: ".3s all",
									}}
								/>
							</div>
							<div
								onClick={toggleFullScreen}
								style={{
									color: "#fff",
									display: "inline-block",
									marginLeft: 16,
								}}>
								<Image
									src='/full_screen.png'
									width={48}
									height={50}
									alt='full screen icon'
									style={{ width: 48, cursor: "pointer" }}
									priority
								/>
							</div>
						</div>
						<div
							style={{
								width: "100%",
								height: "100%",
								filter: "brightness(45%)",
								position: "relative",
							}}>
							<video
								id='back_video'
								preload='auto'
								loop
								style={{
									objectFit: "cover",
									objectPosition: "0px 0px",
									width: "100%",
									height: "100%",
									top: 0,
									left: 0,
								}}
								muted={muted}
								ref={videoRef}
								autoPlay
								playsInline
								>
								<source
									src={videoURL}
									type='video/mp4'></source>
							</video>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
}
